import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Lesson from "../../../components/Lesson";
import TwinMacroExample from "../../../components/TwinMacroExample";
export const meta = {
  published: true,
  publishedAt: "2020-12-07",
  title: "Why I Love Tailwind",
  summary: "Why Tailwind is blowing up, why I (the creator of styled-components) love it and how I avoid the downsides of atomic CSS.",
  image: "/static/images/tailwind.jpeg",
  likes: 767
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><a parentName="p" {...{
        "href": "https://tailwindcss.com"
      }}>{`Tailwind`}</a>{` is an atomic CSS framework that has taken the frontend world by storm. It gives developers without a deep understanding of design the ability to build visually gorgeous, modern user interfaces. `}</p>
    <p>{`If you have not seen it before, here is the canonical Tailwind example from their original homepage:`}</p>
    <pre {...{
      "className": "language-html"
    }}><code parentName="pre" {...{
        "className": "language-html"
      }}><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`shadow-lg flex bg-white rounded-lg p-6 leading-normal`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`h-24 w-24 rounded-full mx-auto mx-0 mr-6`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`src`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`avatar.jpg`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span>{`
  `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-center text-left`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h1`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-lg`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`Erin Lindford`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h2`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-purple-500`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`Customer Support`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-gray-600`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`erinlindford@example.com`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-gray-600`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`(555) 765-4321`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
`}</code></pre>
    <style>{`
.shadow-lg { box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05); }
.p-6 { padding: 1.5rem; }
.leading-normal { line-height: 1.5; }
.rounded-lg { border-radius: .5rem; }
.bg-white { background-color: #fff; }
.h-16 { height: 4rem; }
.w-16 { width: 4rem; }
.rounded-full { border-radius: 9999px; }
.mx-auto { margin-left: auto; margin-right: auto; }
.text-center { text-align: center; }
.text-lg { font-size: 1.125rem; }
.text-purple-500 { color: #9f7aea; }
.text-gray-600 { color: #718096; }
.flex { display: flex; }
.h-24 { height: 6rem; }
.w-24 { width: 6rem; }
.mx-0 { margin-left: 0; margin-right: 0; }
.mr-6 { margin-right: 1.5rem; }
.text-left { text-align: left; }
`}</style>
    <div className="shadow-lg flex bg-white rounded-lg p-6 leading-normal" style={{
      fontFamily: "sans-serif",
      wordBreak: "break-all"
    }}>
  <img alt="Avatar of Erin Lindford" className="h-24 w-24 rounded-full mx-auto mx-0 mr-6" src="https://randomuser.me/api/portraits/women/17.jpg" />
  <div className="text-center text-left">
    <div className="text-lg">Erin Lindford</div>
    <div className="text-purple-500">Customer Support</div>
    <div className="text-gray-600">erinlindford@example.com</div>
    <div className="text-gray-600">(555) 765-4321</div>
  </div>
    </div>
    <br />
    <br />
    <p>{`Many people think Tailwind is cool because it uses atomic CSS. Here is the thing though: `}<strong parentName="p">{`Tailwind is awesome `}<em parentName="strong">{`despite`}</em>{` using atomic CSS, not because of it`}</strong>{`.`}</p>
    <p>{`Hear me out.`}</p>
    <h3>{`The key to Tailwind`}</h3>
    <p>{`We have had `}<a parentName="p" {...{
        "href": "https://github.com/basscss/basscss/commit/ed65eec980c4899d930f2c293f70bc619573456f"
      }}>{`atomic`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/tachyons-css/tachyons/commit/7f27af8d52d8ed03615e23a9db5ff33fc8153729"
      }}>{`CSS`}</a>{` `}<a parentName="p" {...{
        "href": "https://medium.com/buzzfeed-design/introducing-solid-1c16b1bf4868"
      }}>{`frameworks`}</a>{` for almost a decade but none of them have been as critically acclaimed as Tailwind. What makes it different?`}</p>
    <p><strong parentName="p">{`The key to Tailwind's popularity is the painstakingly constructed system of design tokens at the core of the framework.`}</strong>{` The system's carefully selected constraints give developers `}<em parentName="p">{`just`}</em>{` the right guardrails. They make it obvious whether a choice is good or bad by offering only discrete steps.`}</p>
    <p>{`This does require some design `}<em parentName="p">{`taste`}</em>{`, but most frontend engineers I know have developed that over the years of building user interfaces. Tailwind's system lets them turn that taste into implementation without requiring a lot of design `}<em parentName="p">{`skill`}</em>{` — it helps them cross `}<a parentName="p" {...{
        "href": "https://vimeo.com/85040589"
      }}>{`"the gap"`}</a>{`.`}</p>
    <p>{`Tailwind's system is a masterpiece of design. I, and many other developers all around the world, feel empowered by and love it.`}</p>
    <h3>{`The problem with Tailwind`}</h3>
    <p>{`The atomic CSS framework is basically a delivery mechanism that allows developers to apply the system to their UIs. It's undeniable that it has a fantastic developer experience: once you get used to the custom vocabulary you feel like you are flying!`}</p>
    <p>{`However, we have learned over the past decade that `}<a parentName="p" {...{
        "href": "https://jxnblk.com/blog/two-steps-forward/"
      }}>{`atomic CSS has downsides`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Users still have to add a separate setup for the `}<a parentName="li" {...{
          "href": "https://twitter.com/kentcdodds/status/1240868842361913347"
        }}>{`custom CSS they inevitably need`}</a>{` (coined `}<a parentName="li" {...{
          "href": "https://twitter.com/samselikoff/status/1251637275412357121"
        }}>{`"bailwind"`}</a>{`). You cannot get by on `}<em parentName="li">{`just`}</em>{` Tailwind in the real world. Not having a dedicated place for custom styles in the same system can cause maintenance issues down the line.`}</li>
      <li parentName="ul">{`Due to file-size considerations, `}<a parentName="li" {...{
          "href": "https://tailwindcss.com/docs/hover-focus-and-other-states#default-variants-reference"
        }}>{`Tailwind does not include all variants`}</a>{` (e.g. `}<inlineCode parentName="li">{`hover:`}</inlineCode>{`, `}<inlineCode parentName="li">{`sm:`}</inlineCode>{`) for all utilities by default. It leaves it to you to manually configure which ones you need for every single CSS property.`}</li>
      <li parentName="ul">{`Atomic CSS is not ideal for performance. No tooling can extract the `}<em parentName="li">{`per-page`}</em>{` critical CSS, so you end up shipping more CSS to the browser than necessary. The bigger and more dynamic the app, the more unnecessary code you will ship.`}<sup parentName="li" {...{
          "id": "fnref-1"
        }}><a parentName="sup" {...{
            "href": "#fn-1",
            "className": "footnote-ref"
          }}>{`1`}</a></sup></li>
    </ul>
    <h3>{`Tailwind without the downsides`}</h3>
    <p>{`Brent Jackson, the creator of one of the original atomic CSS libraries, said it best in `}<a parentName="p" {...{
        "href": "https://jxnblk.com/blog/two-steps-forward/"
      }}>{`his post on atomic CSS`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“This methodology was created before React was released and was intended for use in template-based user interfaces, including Rails and PHP. It was never designed for functional component-based UI and doesn't take advantage of this new paradigm.”`}</p>
    </blockquote>
    <p>{`Now, here is the thing: you can have your cake and eat it too. You can use Tailwind's marvelous system and fantastic developer experience without the downsides of atomic CSS.`}</p>
    <p>{`How? `}<a parentName="p" {...{
        "href": "https://github.com/ben-rogerson/twin.macro"
      }}>{`twin.macro`}</a>{`.`}</p>
    <p>{`Let me illustrate. Here is the canonical Tailwind example built with `}<a parentName="p" {...{
        "href": "https://github.com/ben-rogerson/twin.macro"
      }}>{`twin.macro`}</a>{` and React:`}</p>
    <pre {...{
      "className": "language-jsx"
    }}><code parentName="pre" {...{
        "className": "language-jsx"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"twin.macro"`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Card`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
  `}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`tw`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`shadow-lg md:flex bg-white rounded-lg p-6 leading-normal`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
    `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`tw`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`h-16 w-16 md:h-24 md:w-24 rounded-full mx-auto md:mx-0 md:mr-6`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`src`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`avatar.jpg`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
    `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`tw`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-center md:text-left`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h1`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`tw`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-lg`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Erin Lindford`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`h2`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`tw`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-purple-500`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`Customer Support`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`tw`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-gray-600`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`erinlindford@example.com`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
      `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`tw`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-gray-600`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`(555) 765-4321`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
    `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
  `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <TwinMacroExample mdxType="TwinMacroExample" />
    <br />
    <p>{`Unsurprisingly, the result looks identical — we are still using the same system after all. Even the code looks the same, except that we use the `}<inlineCode parentName="p">{`tw`}</inlineCode>{` prop instead of the `}<inlineCode parentName="p">{`class`}</inlineCode>{` attribute!`}</p>
    <p>{`However, under the hood this automatically compiles the class names to the actual CSS they refer to (with `}<a parentName="p" {...{
        "href": "https://medium.com/styled-components/announcing-native-support-for-the-css-prop-in-styled-components-245ca5252feb"
      }}>{`the css prop`}</a>{`):`}</p>
    <pre {...{
      "className": "language-jsx"
    }}><code parentName="pre" {...{
        "className": "language-jsx"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"twin.macro"`}</span>{`

`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`tw`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`text-center md:text-left`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// ↓↓↓↓↓ turns into ↓↓↓↓↓`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"styled-components/macro"`}</span>{`

`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`div`}</span>{` 
  `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`css`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    textAlign`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"center"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"@media (min-width: 768px)"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"textAlign"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"left"`}</span>{`
    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span>{`
`}</code></pre>
    <p>{`You get to use Tailwind's system and developer experience `}<em parentName="p">{`and`}</em>{` take advantage of `}<a parentName="p" {...{
        "href": "/thoughts/css-in-js"
      }}>{`all the benefits of CSS-in-JS`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Extending your elements with custom styles is as simple as using the css prop, no extra separate setup required to "bailwind":`}</p>
        <pre parentName="li" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token keyword module"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"twin.macro"`}</span>{`

`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{`
  `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`tw`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`text-center md:text-left`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`css`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`
    &:hover { 
      background-image: url("/bg.png");
    }
  `}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{`
`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can use all variants in all combinations with all utilities allowing for even more expression within the system. Since twin.macro runs at build-time, you don't have to worry about configuration or file size:`}</p>
        <pre parentName="li" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token keyword module"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"twin.macro"`}</span>{`

`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`tw`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`sm:hover:first:bg-black`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You get fully automatic critical CSS extraction and code splitting. Users will only load exactly the styles they need for the page they requested — nothing more and nothing less! CSS performance does not get better.`}<sup parentName="p" {...{
            "id": "fnref-1"
          }}><a parentName="sup" {...{
              "href": "#fn-1",
              "className": "footnote-ref"
            }}>{`1`}</a></sup></p>
      </li>
    </ul>
    <p>{`The ideal setup for both developer `}<em parentName="p">{`and`}</em>{` user experience!`}</p>
    <Lesson title="Lesson" body={<>use <a href="https://github.com/ben-rogerson/twin.macro" target="_blank">twin.macro</a> to leverage Tailwind's marvelous system and developer experience without the downsides of atomic CSS.</>} mdxType="Lesson" />
    <p>{`Rather than taking two steps forward with Tailwind's system and one step backward with atomic CSS, let's take five steps forward. Together.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`CSS-in-JS automatically extracts the critical CSS for the requested page and inlines it into a `}<inlineCode parentName="li">{`<style>`}</inlineCode>{` tag. That means the first-paint with CSS-in-JS will `}<em parentName="li">{`always`}</em>{` be faster as it saves both an extra network request for the `}<inlineCode parentName="li">{`.css`}</inlineCode>{` file as well as sending less CSS code to the client. While the Time To Interactive for small apps will be slightly slower (as the JavaScript bundle includes the CSS-in-JS library you use) once your app grows the network request for the larger CSS file can outweigh any CSS-in-JS library and can cause a slower Time To Interactive as well.`}{` `}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      